import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { TransfereeNeedsAssessment } from 'src/app/core/models/candidateneeds-assessment.model';
import { CollabEvents, CollabMessage } from 'src/app/core/services/collaboration-events.service';
import { CollaborationService } from 'src/app/core/services/collaboration.service';
import { FamilyInfoService } from 'src/app/core/services/family-info.service';
import { LanguageTranslationService } from 'src/app/core/services/language-translation.service';
import { PersonalInfoService } from 'src/app/core/services/personal-info.service';
import { BenefitService } from 'src/app/public/benefits/services/benefit.service';
import { NeedsAssessmentSharedService } from 'src/app/public/needs-assessment/services/needs-assessment-shared.service';
import { LocationsService } from 'src/app/public/needs-assessment/services/locations.service';

@Component({
  selector: 'app-multiple-moves',
  templateUrl: './multiple-moves.component.html',
  styleUrls: ['./multiple-moves.component.scss']
})
export class MultipleMovesComponent implements OnInit, OnDestroy {
  @Input() needsAssessmentDetails;
  moveOrderDetails;
  browserLanguage: string;
  multipleMoves = false;
  @Input() orderRequestId;
  /** Collaboration Subscription */
  collabSubscription: Subscription;
  isLumpsum = false;
  updateRelocationDatesSubscription: Subscription;

  constructor(
    public readonly needsAssessmentShared: NeedsAssessmentSharedService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public cookieService: CookieService,
    public readonly benefitService: BenefitService,
    public familyInfoService: FamilyInfoService,
    public personalInfoService: PersonalInfoService,
    public readonly collaboration: CollaborationService,
    public translate: TranslateService,
    languageTranslationService: LanguageTranslationService,
    private locationService: LocationsService
  ) {
    this.browserLanguage = languageTranslationService.getSupportedLanguage();
    translate.use(this.browserLanguage);
  }

  ngOnInit(): void {
    if (this.needsAssessmentDetails) {
      this.getMoveOrderDetails(this.needsAssessmentDetails);
      this.observeCollab();
    }

    this.updateRelocationDatesSubscription = this.needsAssessmentShared.updateRelocationDate.subscribe(data => {
      if (!data) {
        return;
      }
      const moveOrderIndex = this.moveOrderDetails.findIndex(x => x.orderRequestId === data.orderRequestId);
      if (moveOrderIndex > -1) {
        this.moveOrderDetails[moveOrderIndex].estimatedArrivalDate = data.estimatedArrivalDate;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.updateRelocationDatesSubscription) {
      this.updateRelocationDatesSubscription.unsubscribe();
    }
  }

  getMoveOrderDetails(needsAssessmentData) {
    let departureCode = '';
    let destinationCode = '';
    this.moveOrderDetails = needsAssessmentData.orderRequestDetails.map(moveDetails => {
      this.locationService.countryList.subscribe(countryListData => {
        if (countryListData.length > 0 && moveDetails.departureAddr && moveDetails.departureAddr.country) {
          const depatureCountryCode = countryListData.find(ele => ele.countryName === moveDetails.departureAddr.country);
          departureCode = depatureCountryCode.countryiso3CharCode;
        }
        if (countryListData.length > 0 && moveDetails.destinationAddr && moveDetails.destinationAddr.country) {
          const destinationCountryCode = countryListData.find(ele => ele.countryName === moveDetails.destinationAddr.country);
          destinationCode = destinationCountryCode.countryiso3CharCode;
        }
        moveDetails['departure'] =
          moveDetails.departureAddr && moveDetails.departureAddr.city && departureCode
            ? `${moveDetails.departureAddr.city}, ${departureCode}`
            : '';
        moveDetails['destination'] =
          moveDetails.destinationAddr && moveDetails.destinationAddr.city && destinationCode
            ? `${moveDetails.destinationAddr.city}, ${destinationCode}`
            : '';
      });
      moveDetails['type'] =
        moveDetails.systemOfOrigin === 'Traditional' && moveDetails.hasLumpSum ? 'Lump Sum' : moveDetails.systemOfOrigin;
      moveDetails['isSelected'] = moveDetails.orderRequestId === this.orderRequestId;
      return moveDetails;
    });
    this.multipleMoves = this.moveOrderDetails.length > 1 ? true : false;
  }

  observeCollab() {
    this.collabSubscription = this.collaboration.socketSubscriber$.subscribe((message: CollabMessage) => {
      if (!message) {
        return;
      }

      if (message.data && message.data.event && message.data.event === CollabEvents.MOVE_ORDER_CHANGE) {
        this.getCollabDetails(message.data.data);
      }
    });
  }

  getCollabDetails(moveData) {
    this.orderRequestId = moveData;
    if (this.moveOrderDetails && this.moveOrderDetails.length) {
      this.moveOrderDetails.map(move => {
        move.isSelected = move.orderRequestId === moveData ? true : false;
        return move;
      });
    }
    this.needsAssessmentShared.moveDetails.subscribe(res => {
      if (res) {
        const matchData = res.orderRequestDetails.filter(ele => ele.orderRequestId === moveData);
        const index = res.orderRequestDetails.findIndex(ele => ele.orderRequestId === moveData);
        sessionStorage.setItem('car-ses-oid', matchData[0].orderRequestId);

        if (res.orderRequestDetails[index].confirmStatus || this.cookieService.get('transferee-context')) {
          this.needsAssessmentShared.transfereeNeedsAssessmentDetails.next(this.needsAssessmentShared.transformNeedAssessment(index, res));
          this.familyInfoService.familyInformation.next(res.orderRequestDetails[index].children);
          this.personalInfoService.getPersonalInformationDetails().subscribe(data => {
            if (data) {
              const index = sessionStorage.getItem('car-ses-oid')
                ? data.orderRequestDetails.findIndex(ele => ele.orderRequestId === sessionStorage.getItem('car-ses-oid'))
                : 0;

              this.updatePersonalInfo(this.needsAssessmentShared.transformNeedAssessment(index, data));
            }
          });
          this.router.navigate(['/dashboard']);
          this.getMoveOrderDetails(res);
        } else {
          this.router.navigate(['/needs-assessment']);
        }
      }
    });
  }

  getDetails(moveData) {
    if (this.moveOrderDetails && this.moveOrderDetails.length) {
      this.moveOrderDetails.map(move => {
        move.isSelected = move.orderRequestId === moveData.orderRequestId ? true : false;
        return move;
      });
    }
    this.spinner.show();
    this.needsAssessmentShared.moveDetails.subscribe(res => {
      if (res) {
        const matchData = res.orderRequestDetails.filter(ele => ele.orderRequestId === moveData.orderRequestId);
        const index = res.orderRequestDetails.findIndex(ele => ele.orderRequestId === moveData.orderRequestId);

        this.collaboration.sendMessage({
          data: matchData[0].orderRequestId,
          event: CollabEvents.MOVE_ORDER_CHANGE
        });
        sessionStorage.setItem('car-ses-oid', matchData[0].orderRequestId);

        if (res.orderRequestDetails[index].confirmStatus || this.cookieService.get('transferee-context')) {
          this.needsAssessmentShared.transfereeNeedsAssessmentDetails.next(this.needsAssessmentShared.transformNeedAssessment(index, res));
          setTimeout(() => {
            this.spinner.show();
          }, 100);
          this.familyInfoService.familyInformation.next(res.orderRequestDetails[index].children);
          this.personalInfoService.getPersonalInformationDetails().subscribe(data => {
            if (data) {
              const index = sessionStorage.getItem('car-ses-oid')
                ? data.orderRequestDetails.findIndex(ele => ele.orderRequestId === sessionStorage.getItem('car-ses-oid'))
                : 0;

              this.updatePersonalInfo(this.needsAssessmentShared.transformNeedAssessment(index, data));
            }
          });
          this.router.navigate(['/dashboard']);
          setTimeout(() => {
            this.spinner.hide();
          }, 3000);
        } else {
          this.router.navigate(['/needs-assessment']);
          this.spinner.hide();
        }
      }
    });
  }

  updatePersonalInfo(data: TransfereeNeedsAssessment) {
    this.personalInfoService.personalInformationDetails.next(data);
  }
}
