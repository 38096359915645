import { Injectable, Injector } from '@angular/core';
import { TransfereeNeedsAssessment } from '../../../core/models/candidateneeds-assessment.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { TransfereeNeedsAssessmentService } from './transferee-needs-assessment.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl, ValidationErrors, UntypedFormArray } from '@angular/forms';
import { ProfileForm, LocationForm, ContactForm } from '../model';
import { CommonUtilityService } from 'src/app/core/services/common-utility.service';
import { phoneDetails } from 'src/app/core/models/phone.model';
import { emailDetails } from 'src/app/core/models/email.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocationsService } from './locations.service';
import { Locations } from 'src/app/core/models/locations.model';
import { ContactFormValue } from '../components/contact-details/contact-details.component';
import { phoneTypes as staticPhoneTypes, emailTypes as staticEmailTypes } from 'src/app/core/models/constants';
// import { RSA_NO_PADDING } from 'constants';
import { PersonalInfoService } from 'src/app/core/services/personal-info.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageTranslationService } from '../../../core/services/language-translation.service';

interface ResetStates {
  departureStateList: any[];
  destinationStateList: any[];
}
interface updatedKeyInterface {
  group: 'departureAddr' | 'destinationAddr';
  type: 'city' | 'state';
}

//lumpsum  response data model
export interface DataResponseModel {
  lumpsumDetails: object;
  bankInfo: any[];
  requestLumpsum: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class NeedsAssessmentSharedService {
  /** Instance of BehaviorSubject of type TransfereeNeedsAssessment */
  transfereeNeedsAssessmentDetails = new BehaviorSubject<TransfereeNeedsAssessment>(null);

  transfereeRepaymentLumpSumDetails = new BehaviorSubject(null);

  /** TransfereeNeedsAssessment as Promise */
  needsAssessmentDetails;
  candidateDetails: Observable<TransfereeNeedsAssessment> = this.transfereeNeedsAssessmentDetails.asObservable();
  moveDetails = new BehaviorSubject<any>(null);
  browserLanguage: any;
  tempPhoneString = 'bb.phoneTypes.';
  updateRelocationDate = new BehaviorSubject<any>(null);

  /**
   * Base Constructor
   */
  constructor(
    private readonly transfereeSrvc: TransfereeNeedsAssessmentService,
    private readonly personalInfoService: PersonalInfoService,
    private injector: Injector,
    public translate: TranslateService,
    private languageTranslationService: LanguageTranslationService
  ) {
    this.browserLanguage = this.languageTranslationService.getSupportedLanguage();
    translate.use(this.browserLanguage);
  }
  /**
   * Function to Update the TransfereeNeedsAssessment subject
   * @param data updated TransfereeNeedsAssessment
   */
  updateCandidateNeedsAssesment(data: TransfereeNeedsAssessment) {
    this.transfereeNeedsAssessmentDetails.next(data);
  }

  /**
   * Function to Get transferee needs-Assessment and update Shared-Service
   */
  async getCandidateNeedsAssessmentDetails() {
    if (!this.transfereeNeedsAssessmentDetails.getValue()) {
      const response = await this.transfereeSrvc.getTransfereeNeedsAssessment().toPromise();
      this.moveDetails.next(response);
      if (response && response.orderRequestDetails) {
        const index = response.orderRequestDetails.findIndex(ele => ele.orderRequestId === sessionStorage.getItem('car-ses-oid'));
        const actualIndex = index ? index : 0;
        const needAssessment: TransfereeNeedsAssessment = this.transformNeedAssessment(actualIndex, response);
        this.updateCandidateNeedsAssesment(needAssessment);
        // While calling needassesment api, profile api called again, to avoid that below code is commented
        // this.personalInfoService.personalInformationDetails.next(needAssessment);
        return (this.needsAssessmentDetails = needAssessment);
      } else return null;
    } else {
      return (this.needsAssessmentDetails = this.transfereeNeedsAssessmentDetails.getValue());
    }
  }

  /**
   * Function to transform needAssessment response according to move order
   */
  transformNeedAssessment(index, response) {
    const needAssessment: any = {
      departureAddr: response.orderRequestDetails[index].departureAddr,
      nameDetails: response.nameDetails,
      phoneDetailsList: response.phoneDetailsList,
      emailDetailsList: response.emailDetailsList,
      clientEntityName: response.clientEntityName,
      clientId: response.clientId,
      orderRequestId: response.orderRequestDetails[index].orderRequestId,
      BusnPartEmpId: response.orderRequestDetails[index].BusnPartEmpId,
      destinationAddr: response.orderRequestDetails[index].destinationAddr,
      children: response.orderRequestDetails[index].children,
      benefitDetails: response.orderRequestDetails[index].benefitDetails,
      programName: response.orderRequestDetails[index].programName,
      orderRefNum: response.orderRequestDetails[index].orderRefNum,
      confirmStatus: response.orderRequestDetails[index].confirmStatus,
      totalNumberOfRelocatingMembers: response.orderRequestDetails[index].totalNumberOfRelocatingMembers,
      candidateId: response.candidateId,
      consentInfo: response.orderRequestDetails[index].consentInfo,
      jobStartDate: response.orderRequestDetails[index].jobStartDate,
      estimatedArrivalDate: response.orderRequestDetails[index].estimatedArrivalDate,
      custFileID: response.orderRequestDetails[index].custFileID,
      spousePartnerRelocating: !!response.orderRequestDetails[index].spousePartnerRelocating,
      childrenDependentsRelocating: !!response.orderRequestDetails[index].childrenDependentsRelocating
      // createdAt : response.orderRequestDetails[index].createdAt,
      //children : response.orderRequestDetails[index].children
    };
    response.orderRequestDetails[index].pointDetails
      ? (needAssessment.pointDetails = response.orderRequestDetails[index].pointDetails)
      : null;
    response.orderRequestDetails[index].budgetDetails
      ? (needAssessment.budgetDetails = response.orderRequestDetails[index].budgetDetails)
      : null;
    response.orderRequestDetails[index].isCashedOut ? (needAssessment.isCashedOut = response.orderRequestDetails[index].isCashedOut) : null;
    // Append the type of move order BB or Traditional
    response.orderRequestDetails[index]?.systemOfOrigin
      ? (needAssessment.systemOfOrigin = response.orderRequestDetails[index].systemOfOrigin)
      : null;

    return needAssessment;
  }

  /**
   * Generate Profile Personal Form
   **/
  generateProfileForm(profileFormData: ProfileForm): UntypedFormGroup {
    const isNull = this.injector.get(CommonUtilityService).isNullOrUndefinedSafeCheck;
    const fb = this.injector.get(UntypedFormBuilder);
    const profileForm: UntypedFormGroup = fb.group({
      nameDetails: fb.group({
        title: [isNull(profileFormData.nameDetails, 'title')],
        firstName: [
          isNull(profileFormData.nameDetails, 'firstName'),
          [Validators.required, Validators.minLength(2), Validators.maxLength(50)],
          this.noEmptySpaceValidator
        ],
        lastName: [
          isNull(profileFormData.nameDetails, 'lastName'),
          [Validators.required, Validators.minLength(1), Validators.maxLength(50)],
          this.noEmptySpaceValidator
        ]
      }),
      dependents: fb.group(
        {
          totalNumberOfRelocatingMembers: [
            isNull(profileFormData, 'totalNumberOfRelocatingMembers'),
            [Validators.required, Validators.max(100), Validators.min(1)]
          ],
          spousePartnerRelocating: [profileFormData.spousePartnerRelocating],
          childrenDependentsRelocating: [profileFormData.childrenDependentsRelocating]
        },
        { validators: this.customDependentValidator }
      )
    });
    return profileForm;
  }

  /**
   * Generate Location Form
   * @param locationFormData formdata
   */
  generateLocationForm(locationFormData: LocationForm): UntypedFormGroup {
    const isNull = this.injector.get(CommonUtilityService).isNullOrUndefinedSafeCheck;
    const fb = this.injector.get(UntypedFormBuilder);
    const locationForm: UntypedFormGroup = fb.group({
      departureAddr: fb.group({
        streetLine1: [isNull(locationFormData.departureAddr, 'streetLine1')],
        city: [isNull(locationFormData.departureAddr, 'city'), [Validators.required]],
        state: [isNull(locationFormData.departureAddr, 'state'), [Validators.required]],
        country: [isNull(locationFormData.departureAddr, 'country'), [Validators.required]],
        postalCode: [
          isNull(locationFormData.departureAddr, 'postalCode'),
          [Validators.required, Validators.minLength(1), Validators.maxLength(15)]
        ]
      }),
      destinationAddr: fb.group({
        streetLine1: [isNull(locationFormData.destinationAddr, 'streetLine1')],
        city: [isNull(locationFormData.destinationAddr, 'city'), [Validators.required]],
        state: [isNull(locationFormData.destinationAddr, 'state'), [Validators.required]],
        country: [isNull(locationFormData.destinationAddr, 'country'), [Validators.required]],
        postalCode: [
          isNull(locationFormData.destinationAddr, 'postalCode'),
          [Validators.required, Validators.minLength(1), Validators.maxLength(15)]
        ]
      })
    });
    return locationForm;
  }

  /**
   * Generates Contact Form with phone and email details
   * @param contactFormData
   */
  generateContactForm(contactFormData: ContactForm): UntypedFormGroup {
    const fb = this.injector.get(UntypedFormBuilder);
    const contactForm = fb.group({
      phoneDetails: fb.array(this._generatePhoneGroup(contactFormData.phoneDetailsList)),
      emailDetails: fb.array(this._generateEmailGroup(contactFormData.emailDetailsList))
    });
    return contactForm;
  }

  /**
   * Generates phone array for Contact Form
   * @param phoneDetails phonedetails data
   */
  private _generatePhoneGroup(phoneDetails: phoneDetails[]): UntypedFormGroup[] {
    const fb = this.injector.get(UntypedFormBuilder);
    const isNull = this.injector.get(CommonUtilityService).isNullOrUndefinedSafeCheck;
    if (phoneDetails) {
      phoneDetails = [...phoneDetails];
      phoneDetails.forEach((phoneDetail, index) => {
        let phoneType = null;
        phoneDetail.textingAvailable ? (phoneType = this.translate.instant('bb.phoneTypes.mobile')) : null;

        if (!phoneDetail.textingAvailable) {
          phoneDetail.usageType == 'business' && phoneDetail.locationType == 'departure'
            ? (phoneType = this.translate.instant('bb.phoneTypes.departureBusiness'))
            : null;
          phoneDetail.usageType == 'business' && phoneDetail.locationType == 'destination'
            ? (phoneType = this.translate.instant('bb.phoneTypes.destinationBusiness'))
            : null;
          phoneDetail.usageType == 'personal' && phoneDetail.locationType == 'departure'
            ? (phoneType = this.translate.instant('bb.phoneTypes.departureResidence'))
            : null;
          phoneDetail.usageType == 'personal' && phoneDetail.locationType == 'destination'
            ? (phoneType = this.translate.instant('bb.phoneTypes.destinationResidence'))
            : null;
        }

        delete phoneDetail.usageType;

        // Extra check to ensure there is only one occurence of type even if backend send same.
        if (index !== 0) {
          typeof phoneDetails.find(phone => phone.type === phoneType) == 'undefined' ? (phoneDetail.type = phoneType) : null;
        } else {
          phoneDetail.type = phoneType;
        }
      });

      const phoneDetailsArray: UntypedFormGroup[] = phoneDetails.map(phoneDetail => {
        return fb.group({
          _id: [isNull(phoneDetail, '_id')],
          primary: [isNull(phoneDetail, 'primary')],
          type: [{ value: isNull(phoneDetail, 'type'), disabled: true }, [Validators.required]],
          phoneDialCode: [isNull(phoneDetail, 'phoneDialCode'), [Validators.required]],
          phoneNumber: [
            isNull(phoneDetail, 'phoneNumber'),
            [
              Validators.required,
              Validators.minLength(7),
              Validators.maxLength(18),
              Validators.min(1000000),
              Validators.max(999999999999999),
              Validators.pattern('^[0-9]*$')
            ]
          ]
        });
      });

      // Extra Check
      phoneDetailsArray.map(phone => {
        if (!phone.getRawValue().type) {
          phone.enable({ onlySelf: true, emitEvent: false });
        }
      });

      phoneDetailsArray;

      return phoneDetailsArray;
    }
    //  else {
    //   const phoneDetailsArray = new Array(fb.group({
    //     _id: [''],
    //     primary: [''],
    //     type: ['', []],
    //     phoneDialCode: ['', []],
    //     phoneNumber: ['', [Validators.minLength(7), Validators.maxLength(18),
    //       Validators.min(1000000), Validators.max(999999999999999), Validators.pattern('^[0-9]*$')]]
    //   }
    //     // , {
    //     //     validators: this.requiredPhoneValidator
    //     // }
    //   ));
    //   return phoneDetailsArray;
    // }
  }

  /**
   * Generates email array for contact details form
   * @param emailDetails email list
   */
  private _generateEmailGroup(emailDetails: emailDetails[]): UntypedFormGroup[] {
    const fb = this.injector.get(UntypedFormBuilder);
    const isNull = this.injector.get(CommonUtilityService).isNullOrUndefinedSafeCheck;
    if (emailDetails) {
      const emailDetailsArray = emailDetails.map(emailDetail => {
        return fb.group({
          _id: [isNull(emailDetail, '_id')],
          primary: [isNull(emailDetail, 'primary')],
          emailAddress: [
            isNull(emailDetail, 'emailAddress'),
            [
              Validators.required,
              Validators.pattern(
                "^[A-Za-z0-9!#$%&'*+/=?^_‘{|}~-]+(?:\\.[A-Za-z0-9!#$%&'*+/=?^_‘{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])$"
              )
            ]
          ],
          usageType: [isNull(emailDetail, 'usageType'), [Validators.required]]
        });
      });

      // Disabled if type is business...
      emailDetailsArray.map(email => {
        if (email.getRawValue().usageType) {
          email.controls.usageType.disable();
        }
      });

      return emailDetailsArray;
    }
    // else {
    //   const emailDetailsArray = new Array(fb.group({
    //     _id: [''],
    //     primary: [''],
    //     emailAddress: ['', [, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]+[a-zA-Z0-9-]*\\.[a-zA-Z]{2,3}$')]],
    //     usageType: [''],
    //   }
    //     // , {
    //     //     validators: this.requiredEmailValidator
    //     // }
    //   ));
    //   return emailDetailsArray;
    // }
  }

  private noEmptySpaceValidator(input: AbstractControl): ValidationErrors | null {
    return new Promise(resolve => {
      if (input.value && input.value.trim() === '') {
        resolve({ required: true });
      } else {
        resolve(null);
      }
    });
  }

  /**
   * Validate spouse/dependents
   * @param locationFormData formGroup
   */
  customDependentValidator(group: UntypedFormGroup) {
    const relocatingMembers = group.controls['totalNumberOfRelocatingMembers'].value;
    const spousePartner = group.controls['spousePartnerRelocating'].value;
    const childrenDependent = group.controls['childrenDependentsRelocating'].value;
    /** Form Validation */
    if (relocatingMembers > 1 && spousePartner != true && childrenDependent != true) {
      return {
        customDependentValidator: true
      };
    } else {
      return null;
    }
  }

  // /**
  //  * Validate if all values are entered
  //  * @param phoneformGroup formGroup
  //  */
  // requiredPhoneValidator(group: FormGroup) {
  //   const phoneDialCode = group.controls['phoneDialCode'].value;
  //   const phoneNumber = group.controls['phoneNumber'].value;
  //   const type = group.controls['type'].value;
  //   if (((phoneDialCode && phoneNumber && type) || (!phoneDialCode && !phoneNumber && !type))) {
  //     return null;
  //   } else {
  //     return {
  //       'requiredPhoneValidator': true
  //     };
  //   }
  // }

  // /**
  //  * Validate if all values are entered
  //  * @param emailformGroup formGroup
  //  */
  // requiredEmailValidator(group: FormGroup) {
  //   const emailAddress = group.controls['emailAddress'].value;
  //   const usageType = group.controls['usageType'].value;
  //   if ((emailAddress && usageType) || (!emailAddress && !usageType)) {
  //     return null;
  //   } else {
  //     return {
  //       'requiredEmailValidator': true
  //     };
  //   }
  // }

  /**
   * State/Province required for locations in the United States of America and Canada only
   */
  modifyLocationFormValidations(addressForm: UntypedFormGroup, departureCountryCode: string, destinationCountryCode: string, type: string) {
    if (!['US', 'CA'].includes(departureCountryCode)) {
      (addressForm.controls.departureAddr as UntypedFormGroup).controls.state.clearValidators();
      (addressForm.controls.departureAddr as UntypedFormGroup).controls.postalCode.clearValidators();
      (addressForm.controls.departureAddr as UntypedFormGroup).controls.postalCode.setValidators([
        Validators.minLength(1),
        Validators.maxLength(15)
      ]);
    } else {
      (addressForm.controls.departureAddr as UntypedFormGroup).controls.postalCode.clearValidators();
      (addressForm.controls.departureAddr as UntypedFormGroup).controls.postalCode.setValidators([
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(50)
      ]);
    }
    if (!['US', 'CA'].includes(destinationCountryCode)) {
      (addressForm.controls.destinationAddr as UntypedFormGroup).controls.state.clearValidators();
      (addressForm.controls.destinationAddr as UntypedFormGroup).controls.postalCode.clearValidators();
      (addressForm.controls.destinationAddr as UntypedFormGroup).controls.postalCode.setValidators([
        Validators.minLength(1),
        Validators.maxLength(15)
      ]);
    } else if (['US', 'CA'].includes(destinationCountryCode) && type == 'profile') {
      (addressForm.controls.destinationAddr as UntypedFormGroup).controls.postalCode.clearValidators();
      (addressForm.controls.destinationAddr as UntypedFormGroup).controls.postalCode.setValidators([
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(50)
      ]);
    } else {
      (addressForm.controls.destinationAddr as UntypedFormGroup).controls.postalCode.clearValidators();
      (addressForm.controls.destinationAddr as UntypedFormGroup).controls.postalCode.setValidators([
        Validators.minLength(2),
        Validators.maxLength(50)
      ]);
    }
  }

  /**
   * Will determine which field has been updated in Address Form (for Address and Review Component).
   * @param newFormValue AddressForm new values
   * @param oldFormValue AddressForm old values
   */
  findUpdatedKey(newFormValue: LocationForm, oldFormValue: LocationForm): updatedKeyInterface {
    let updatedKey = null;

    Object.keys(newFormValue.departureAddr).map(key => {
      if (newFormValue.departureAddr[key] !== oldFormValue.departureAddr[key]) {
        updatedKey = key;
      }
    });

    if (updatedKey) {
      return { group: 'departureAddr', type: updatedKey };
    }

    Object.keys(newFormValue.destinationAddr).map(key => {
      if (newFormValue.destinationAddr[key] !== oldFormValue.destinationAddr[key]) {
        updatedKey = key;
      }
    });

    if (updatedKey) {
      return { group: 'destinationAddr', type: updatedKey };
    }

    return null;
  }

  /**
   * Shared function for Location Search based on city or state...
   * @param addressForm
   * @param updatedKey
   * @param updatedLocationFormValue
   * @param departureCountryCode
   * @param destinationCountryCode
   * @param spinner
   * @param locationService
   */
  locationSearch(
    addressForm: UntypedFormGroup,
    updatedKey: updatedKeyInterface,
    updatedLocationFormValue: any,
    departureCountryCode: string,
    destinationCountryCode: string
  ): Observable<Locations> {
    return Observable.create(_observer => {
      const spinner = this.injector.get(NgxSpinnerService);
      const locationService = this.injector.get(LocationsService);

      // if empty then don't run...
      if (!updatedLocationFormValue[updatedKey.group][updatedKey.type]) {
        return;
      }

      const searchType = updatedKey.type === 'city' ? 'cities' : 'states';
      const searchText: string = updatedLocationFormValue[updatedKey.group as any][updatedKey.type];

      // Search only if search text length is greater than 3;
      if (searchText.length < 3) {
        return;
      }

      spinner.show();

      locationService
        .locationSearch(searchType, updatedLocationFormValue[updatedKey.group as any][updatedKey.type], {
          country: updatedKey.group === 'departureAddr' ? departureCountryCode : destinationCountryCode,
          cityLongName:
            updatedKey.group == 'departureAddr'
              ? updatedLocationFormValue.departureAddr.city
              : updatedLocationFormValue.destinationAddr.city,
          cityShortName:
            updatedKey.group == 'departureAddr'
              ? updatedLocationFormValue.departureAddr.city
              : updatedLocationFormValue.destinationAddr.city
        })
        .subscribe(
          data => {
            spinner.hide();

            // Empty response..
            if (!data) {
              if (updatedKey.group == 'departureAddr') {
                updatedKey.type == 'city'
                  ? (addressForm.controls.departureAddr as UntypedFormGroup).controls.city.setValue('')
                  : (addressForm.controls.departureAddr as UntypedFormGroup).controls.state.setValue('');
              } else {
                // destinationAddr
                updatedKey.type == 'city'
                  ? (addressForm.controls.destinationAddr as UntypedFormGroup).controls.city.setValue('')
                  : (addressForm.controls.destinationAddr as UntypedFormGroup).controls.state.setValue('');
              }
              addressForm.markAllAsTouched();

              _observer.next(null);
              _observer.complete();
            }

            _observer.next(data);
            _observer.complete();
          },
          () => {
            _observer.next(null);
            _observer.complete();
          },
          () => spinner.hide()
        );
    });
  }

  /** Create Phone Email Sub List used by needs assessment and add family information */
  initPhoneEmailList(
    contactFormValue: ContactFormValue,
    phoneTypesList: any,
    emailTypeList: string[][]
  ): { phoneTypes: string[][]; emailTypes: string[][] } {
    const usedPhoneTypes = [];
    const usedEmailTypes = [];

    contactFormValue.phoneDetails.forEach((phone, index) => {
      phoneTypesList[index] = this.getTranslateTypes(staticPhoneTypes, this.tempPhoneString);
      if (index == 0) {
        usedPhoneTypes.push(phone.type);
      } else {
        phoneTypesList[index] = phoneTypesList[index].filter(phoneType => !usedPhoneTypes.includes(phoneType));
      }
    });

    contactFormValue.emailDetails.forEach((email, index) => {
      emailTypeList[index] = [...staticEmailTypes];
      if (index == 0) {
        usedEmailTypes.push(email.usageType);
      } else {
        emailTypeList[index] = emailTypeList[index].filter(emailType => !usedEmailTypes.includes(emailType));
      }
    });

    return { phoneTypes: phoneTypesList, emailTypes: emailTypeList };
  }

  /**
   * Shared function for is phone value entred is valid or not
   * @param event any
   * @param index Index of the phone array
   * @param contactForm reference of the contact form.
   */
  changePhone(event: any, index: number, contactForm: UntypedFormGroup) {
    let phoneValue = event.target.value;
    phoneValue = phoneValue.replace(/[^0-9]*/g, '');
    if (!isNaN(parseInt(phoneValue))) {
      (contactForm.controls.phoneDetails as UntypedFormArray).controls[index].patchValue({
        phoneNumber: parseInt(phoneValue)
      });
    } else {
      contactForm.setErrors({ invalid: true });
      contactForm.controls.phoneDetails.setErrors({ invalid: true });
    }

    // Extra check for Validation check. Min and Max check
    if (phoneValue.length > 18 || phoneValue.length < 7) {
      event.path[4].classList.add('mat-form-field-invalid');
      event.path[4].classList.add('ng-invalid');
      contactForm.setErrors({ invalid: true });
      contactForm.controls.phoneDetails.setErrors({ invalid: true });
    } else {
      event.path[4].classList.remove('mat-form-field-invalid');
      event.path[4].classList.remove('ng-invalid');
      if (isNaN(parseInt(phoneValue))) {
        contactForm.setErrors(null);
        contactForm.controls.phoneDetails.setErrors(null);
      }
    }
  }

  /**
   * Converting Phone Details List for API to understand as there is no .type key used by needs assessment and add family information
   * @param phoneDetails List of Phones
   */
  updatePhoneType(phoneDetails: phoneDetails[]): phoneDetails[] {
    phoneDetails.forEach(phoneDetail => {
      phoneDetail.type === this.translate.instant('bb.phoneTypes.mobile')
        ? ((phoneDetail.usageType = 'personal'), (phoneDetail.textingAvailable = true))
        : false;
      phoneDetail.type == this.translate.instant('bb.phoneTypes.departureBusiness')
        ? ((phoneDetail.usageType = 'business'), (phoneDetail.locationType = 'departure'))
        : null;
      phoneDetail.type == this.translate.instant('bb.phoneTypes.destinationBusiness')
        ? ((phoneDetail.usageType = 'business'), (phoneDetail.locationType = 'destination'))
        : null;
      phoneDetail.type == this.translate.instant('bb.phoneTypes.departureResidence')
        ? ((phoneDetail.usageType = 'personal'), (phoneDetail.locationType = 'departure'))
        : null;
      phoneDetail.type == this.translate.instant('bb.phoneTypes.destinationResidence')
        ? ((phoneDetail.usageType = 'personal'), (phoneDetail.locationType = 'destination'))
        : null;
      delete phoneDetail['type'];

      // Convert phone number to string
      phoneDetail.phoneNumber = phoneDetail.phoneNumber.toString();

      // Delete Phone Id if its newly added
      phoneDetail._id === '' ? delete phoneDetail._id : null;
    });

    return phoneDetails;
  }

  /**
   * Delete empty Id's for newly created emails
   * @param emailDetails List of emails
   */
  updateEmailDetails(emailDetails: emailDetails[]): emailDetails[] {
    emailDetails.forEach(emailDetail => {
      // Delete email Id if its newly added
      emailDetail._id === '' ? delete emailDetail._id : null;
    });
    return emailDetails;
  }

  /**
   * Add row to Mobile and Email
   * @param type location a new row to be added
   * @param contactForm Contact Form
   * @param newIndex index of the location
   * @param phoneTypesList Phone List
   * @param emailTypesList Email List
   */
  addNewPhoneEmailRow(type: 'phone' | 'email', contactForm: UntypedFormGroup, newIndex: number, phoneTypesList: any, emailTypesList: any) {
    const fb = this.injector.get(UntypedFormBuilder);
    if (type == 'phone') {
      // need to find used phone types and filter from existing.
      const existingPhoneTypes: string[] = ((contactForm.controls.phoneDetails as UntypedFormArray).getRawValue() as any).map(
        phone => phone.type
      );

      // updating phoneTypes
      phoneTypesList[newIndex] = this.getTranslateTypes(staticPhoneTypes, this.tempPhoneString);
      phoneTypesList[newIndex] = phoneTypesList[newIndex].filter(phone => !existingPhoneTypes.includes(phone));

      (contactForm.controls.phoneDetails as UntypedFormArray).push(
        fb.group({
          primary: newIndex === 0 ? true : false,
          type: [null, [Validators.required]],
          phoneDialCode: [null, [Validators.required]],
          phoneNumber: [
            null,
            [
              Validators.required,
              Validators.minLength(7),
              Validators.maxLength(18),
              Validators.min(1000000),
              Validators.max(999999999999999),
              Validators.pattern('^[0-9]*$')
            ]
          ]
        })
      );
    } else {
      // need to find used phone types and filter from existing.
      const existingEmailTypes: string[] = ((contactForm.controls.emailDetails as UntypedFormArray).getRawValue() as any).map(
        email => email.usageType
      );

      // updating emailTypes
      emailTypesList[newIndex] = [...staticEmailTypes];
      emailTypesList[newIndex] = emailTypesList[newIndex].filter(email => !existingEmailTypes.includes(email));

      (contactForm.controls.emailDetails as UntypedFormArray).push(
        fb.group({
          primary: newIndex === 0 ? true : false,
          emailAddress: [
            null,
            [
              Validators.required,
              Validators.pattern(
                "^[A-Za-z0-9!#$%&'*+/=?^_‘{|}~-]+(?:\\.[A-Za-z0-9!#$%&'*+/=?^_‘{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])$"
              )
            ]
          ],
          usageType: [null, [Validators.required]]
        })
      );
    }
  }

  /**
   * Remove row from Mobile or Email and insert the deleted email/phone type to the list of email/phone
   * used by needs assessment and add family information
   * @param type location of the row
   * @param index index of the location
   * @param contactForm Contact Form
   * @param phoneTypes Phone List
   * @param emailTypes Email List
   */
  deletePhoneEmailRow(type: 'phone' | 'email', index: number, contactForm: UntypedFormGroup, phoneTypes: any, emailTypes: any): string[][] {
    if (type === 'phone') {
      const missingPhoneType: string = (contactForm.getRawValue() as ContactFormValue).phoneDetails[index].type;
      if (missingPhoneType) {
        phoneTypes = phoneTypes.map(phoneType => {
          phoneType.push(missingPhoneType);
          return [...new Set(phoneType)];
        });
      }
      (contactForm.controls.phoneDetails as UntypedFormArray).removeAt(index);
      return phoneTypes;
    } else {
      const missingEmailType: string = (contactForm.getRawValue() as ContactFormValue).emailDetails[index].usageType;
      if (missingEmailType) {
        emailTypes = emailTypes.map(emailType => {
          emailType.push(missingEmailType);
          return [...new Set(emailType)];
        });
      }
      (contactForm.controls.emailDetails as UntypedFormArray).removeAt(index);
      return emailTypes;
    }
  }

  /**
   * Will update the selected benefit count with new point number
   * @param numberToAdd Amount of point to be added
   * @param type decides whether its a add or subtract operation
   */
  updateBenefitCount(numberToAdd: number, type: 'add' | 'subtract' | 'equal') {
    const needsAssessment = { ...this.transfereeNeedsAssessmentDetails.getValue() };
    if (needsAssessment.pointDetails) {
      if (type === 'add') {
        needsAssessment.pointDetails.selectedPoints = (needsAssessment.pointDetails.selectedPoints || 0) + numberToAdd;
      } else if (type === 'subtract') {
        needsAssessment.pointDetails.selectedPoints = needsAssessment.pointDetails.selectedPoints - numberToAdd;
      } else {
        needsAssessment.pointDetails.selectedPoints = numberToAdd;
      }
    } else if (needsAssessment.budgetDetails) {
      if (type === 'add') {
        needsAssessment.budgetDetails.selectedAmount = (needsAssessment.budgetDetails.selectedAmount || 0) + numberToAdd;
      } else if (type === 'subtract') {
        needsAssessment.budgetDetails.selectedAmount = needsAssessment.budgetDetails.selectedAmount - numberToAdd;
      } else {
        needsAssessment.budgetDetails.selectedAmount = numberToAdd;
      }
    } else {
      needsAssessment.pointDetails = {
        selectedPoints: numberToAdd
      };
    }

    // Update needsassessment again.
    this.transfereeNeedsAssessmentDetails.next(needsAssessment);
  }

  /**
   * Will be tranlate the list of data
   * @param staticTypes list
   * @param temp which types are need to be tranlate
   */
  getTranslateTypes(staticTypes, temp) {
    const translatedTypes = [];
    staticTypes.forEach(element => {
      translatedTypes.push(this.translate.instant(`${temp}${element}`));
    });
    return translatedTypes;
  }

  /**
   * Will be tranlate the list of data
   * @param drop down value
   * @param temp Key value
   */
  getTranslated(dropdownValue, temp) {
    const dropdownValueTemp = dropdownValue;
    let tranlatedValue =
      dropdownValueTemp &&
      dropdownValueTemp
        .replace(/ +/g, '')
        .toLowerCase()
        .replaceAll('/', '');
    tranlatedValue = this.translate.instant(`${temp}${tranlatedValue}`);
    if (!tranlatedValue.includes('bb.')) {
      return tranlatedValue;
    }
    return dropdownValue;
  }
}
