<mat-card appearance="outlined" class="card-container-moves" *ngIf="multipleMoves">
  <mat-card-title class="header-moves"
    ><img
      class="repeat-img"
      src="../../../../assets/images/repeat.svg"
      alt="repeat"
      role="img"
      width="2%"
      height="20%"
      fxFlex.sm="101px"
      fxFlex.xs="101px"
    />Moves</mat-card-title
  >
  <mat-card-content class="card-content" fxLayoutGap="10px" fxLayoutGap.sm="0px" fxLayoutGap.xs="0px">
    <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="15px">
      <ng-container *ngFor="let moveOrder of moveOrderDetails">
        <mat-card
          fxFlex="30%"
          fxFlex.xs="100%"
          [ngClass]="moveOrder.isSelected ? 'card-border' : ''"
          (click)="getDetails(moveOrder)"
          *ngIf="moveOrder.type === 'BenefitsBuilder'"
        >
          <mat-card-content>
            <span>
              <img
                class="bb-icon"
                src="../../../../assets/images/benefits-builder-icon.svg"
                alt="benefits_builder"
                role="img"
                width="25%"
                height="20%"
                fxFlex.sm="101px"
                fxFlex.xs="101px"
            /></span>
            <div class="location">
              <span
                >{{ moveOrder.departure }}
                <img
                  class="arrow-container"
                  src="../../../../assets/images/arrow-right.svg"
                  alt="arrow-right"
                  role="img"
                  fxFlex.sm="101px"
                  fxFlex.xs="101px"
                />
                {{ moveOrder.destination }}</span
              >
            </div>
          </mat-card-content>
        </mat-card>
      </ng-container>
    </div>
  </mat-card-content>
</mat-card>
